<template>
  <div>
    <div v-if="geocodeFail"><geocode-gate :to-geocode="lowGecodeScore" /></div>

    <div v-if="!geocodeFail">
      <div v-if="!mapOut">
        <routing-map
          ref="maprouting"
          :filtered-data="allRoutes"
          :routes="allRoutes"
          :style="mapStyle"
          :display-tools="displayTools"
        >
          <map-left-menu slot="left" @updateTools="updateTools">
          </map-left-menu>

          <sidebar
            slot="mapleft"
            :type="$store.getters.sidebar"
            :unplanned-route="unplannedRoute"
            :routes="allRoutes"
            :resources="resources"
          ></sidebar>

          <!-- <tab-map-open slot="maptools" @open="openMap" /> -->
        </routing-map>
      </div>

      <!-- <tab-map-server
        :routes="allRoutes"
        :enabled="mapOut"
        @open="openMap"
        @close="closeMap"
      >
      </tab-map-server> -->

      <resizer v-show="!mapOut" @resized="resized" />
      <middlebar class="primary-color-dark-bg">
        <drag-selected slot="start"></drag-selected>
        <cs-button
          slot="end"
          type="primary"
          :disabled="!hasChange"
          :loading="saveLoad"
          @click="save"
        >
          {{ $t('commons.save') }}
        </cs-button>
        <cs-button slot="end" :disabled="!hasChange" @click="cancel">
          {{ $t('commons.cancel') }}
        </cs-button>
      </middlebar>

      <gantt
        ref="gantt"
        editable
        :routes="allRoutes"
        :max-height="maxGanttHeight"
      >
      </gantt>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { concat, filter, get, isEmpty } from 'lodash';
import { hasPosition } from '@/utils';
import Route from '@/models/Route';
import { differenceInMinutes } from 'date-fns';
import Resizer from '@/components/Resizer';
import GeocodeGate from '@/components/GeocodeGate';
import DragSelected from '@/components/sidebar/selection/DragSelected';
import Gantt from '@/components/gantt/GanttRouting';
import MapLeftMenu from '@/components/MapLeftMenu';
import RoutingMap from '@/components/Map';
// import TabMapServer from '@/components/tabmap/TabMapServer';
// import TabMapOpen from '@/components/tabmap/TabMapOpen';
import Sidebar from '@/components/sidebar/Sidebar';
import Middlebar from '@/components/Middlebar';
import { routeModel } from '@/models/filter-models';

export default {
  name: 'Routingview',
  components: {
    DragSelected,
    // TabMapServer,
    // TabMapOpen,
    Sidebar,
    RoutingMap,
    MapLeftMenu,
    Gantt,
    GeocodeGate,
    Middlebar,
    Resizer,
  },
  data: () => ({
    dataModels: {
      route: routeModel.properties,
    },
    unplannedRoute: {
      id: null,
      state: '#unplanned',
      name: '',
      deliveries: [],
      displayMarkers: true,
    },
    displayTools: true,
    searchTerm: '',
    select: {},
    mapOut: false,
    saveLoad: false,
    mapStyle: {
      height: '47vh',
    },
    maxGanttHeight: '43vh',
  }),
  computed: {
    ...mapGetters([
      'dateRange',
      'routes',
      'resources',
      'deliveries',
      'selection',
      'optimizations',
      'unplannedByDate',
      'routesByDate',
    ]),
    hasChange() {
      return this.deliveries.hasAction || this.routes.hasAction;
    },
    lowGecodeScore() {
      if (this.unplannedRoute) {
        return filter(
          this.unplannedRoute.deliveries,
          (d) => !hasPosition(d.place) && get(d, 'place_geocoded.score', 0) < 79
        );
      }
      return false;
    },
    geocodeFail() {
      return !isEmpty(this.lowGecodeScore);
    },
    allRoutes() {
      return concat(filter(this.routesByDate, ['state', 'created']), [
        this.unplannedRoute,
      ]);
    },
  },
  watch: {
    unplannedByDate(v) {
      this.unplannedRoute.deliveries = v;
    },
  },
  mounted() {
    this.$store.dispatch('setDatePicker', {
      clickHandle: this.fetchData,
      display: true,
    });
    if (
      isEmpty(this.routes.items) ||
      differenceInMinutes(new Date(), this.routes.lastLoad) > 5
    ) {
      this.fetchData();
    }
  },
  created() {
    this.unplannedRoute.name = this.$t('pages.routes.activityRemaining');
    this.unplannedRoute.deliveries = this.unplannedByDate;
    this.unplannedRoute = new Route(this.unplannedRoute);
  },
  beforeDestroy() {
    this.cancel();
    this.clearSelection();
    this.$store.dispatch('setDisplayDatePicker', false);
  },
  methods: {
    ...mapActions([
      'get',
      'cancelActionQueue',
      'clearSelection',
      'processActionQueue',
      'cancelAction',
      'sequentialProcessActionQueue'
    ]),
    resized(dim) {
      this.mapStyle.height = `${dim.top - 60}px`;
      this.$refs.maprouting.$refs.map.mapObject.invalidateSize();
      this.maxGanttHeight = `${dim.bottom - 20}px`;
    },
    openMap() {
      this.mapOut = true;
      this.maxGanttHeight = '80vh';
    },
    closeMap() {
      this.mapOut = false;
      this.maxGanttHeight = '43vh';
    },
    updateTools(v) {
      this.displayTools = v;
    },
    fitGantt() {
      this.$refs.gantt.fit();
    },
    fetchData() {
      this.$store.dispatch('setDatePickerLoading', true);
      const getRoute = this.get({
        type: 'route',
        id: 'all',
        query: {
          from: this.dateRange.from,
          to: this.dateRange.to,
          include: 'deliveries',
          exclude: 'deliveries.items',
          state: 'created',
        },
      });

      const getResource = this.get({
        type: 'resource',
        id: 'all',
      });

      const getDeliveries = this.get({
        type: 'delivery',
        id: 'all',
        clear: false,
        query: {
          from: this.dateRange.from,
          to: this.dateRange.to,
          unrouted: true,
          exclude: 'items',
        },
      });

      const getOptimization = this.get({
        type: 'optimization',
        id: 'all',
        query: { from: this.dateRange.from, to: this.dateRange.to },
      });

      Promise.all([getRoute, getResource, getDeliveries, getOptimization]).then(
        () => {
          this.$store.dispatch('setDatePickerLoading', false);
        }
      );
    },
    save() {
      // TODO: fix cancel action
      // forEach(this.deliveries.actionQueue.save, activity => {
      //   if (has(this.routes.actionQueue.save, activity.route_id)) {
      //     this.cancelAction({
      //       action: 'patch',
      //       type: 'delivery',
      //       data: activity
      //     });
      //   }
      // });
      this.sequentialProcessActionQueue(['route', 'delivery']);
      setTimeout(this.$refs.gantt?.redraw, 3000);
    },
    cancel() {
      this.cancelActionQueue(['delivery', 'route']).then(() => {
        this.$refs.gantt?.redraw();
      });
    },
  },
};
</script>
