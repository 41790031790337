import { sync } from 'vuex-router-sync';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import VueClipboards from 'vue-clipboards';
import VueShortkey from 'vue-shortkey';
import PortalVue from 'portal-vue';
import VueAxios from 'vue-axios';

import router from './router';
import store from './store';
import i18n from './locales';
import VueFiltersPlugin from './plugins/vue-filters';
import Echo from './plugins/echo';
import StandardMessage from './plugins/standard-message';
import CsButton from './components/elements/custom-elements';
import axios from './services/axios';
import { AuthPlugin } from './services/auth';
import Main from './pages/Main';
import BaseLayout from './layouts/BaseLayout';
import './styles/_app.scss';
import 'element-ui/packages/theme-chalk/src/index.scss';
import 'font-awesome/css/font-awesome.css';

Vue.use(VueAxios, axios);
Vue.use(PortalVue);
Vue.use(CsButton);
Vue.use(Echo);
Vue.use(VueClipboards);
Vue.use(VueShortkey, { prevent: ['input', 'textarea'] });
Vue.use(VueFiltersPlugin);
Vue.use(StandardMessage, {
  i18n,
});
Vue.component('base-layout', BaseLayout);
Vue.use(AuthPlugin);

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.info(`Logitricks version: ${process.env.VUE_APP_VERSION}`);
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://9994b52c838a81fdd4466aab9aa4184f@sentry.leo-antares.com/30',
    Vue,
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,
  });
}
const app = new Vue({ store, router, i18n, extends: Main }).$mount('#app');
sync(app.$store, app.$router);
